import React, { useState, useEffect } from 'react';

import ReactGA from "react-ga";

import projectsData from "./data/data";

import NavBar from './navbar/NavBar.js';
import Background from './components/Background';
import Carousel from './components/Carousel';
import Box from './components/Box';
import Perfil from './components/Perfil';
import Academico from './components/Academico';
import Profissional from './components/Profissional';
import Footer from './components/Footer';

import "./App.css";


 
const TRACKING_ID = "UA-89355742-2";
ReactGA.initialize(TRACKING_ID);


const App = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const [backgroundImage, setBackgroundImage] = useState(projectsData[0]?.imageUrl || '');
  const [selectedProject, setSelectedProject] = useState(projectsData[0] || {});
  const [activeSection, setActiveSection] = useState('Projects');

  const handleBoxClick = (project) => {
    setBackgroundImage(project.imageUrl);
    setSelectedProject(project);

    ReactGA.event({
      category: 'Project Click',
      action: 'View Project Button Clicked',
      label: project.title, // Use a unique identifier or project title
    });


  };

  const handleNavLinkClick = (section) => {
    setActiveSection(section);

    // Log the section and attempt to scroll
    console.log('Scrolling to section:', section);

    const sectionElement = document.getElementById(section);
    console.log('Section Element:', sectionElement);

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.warn(`Element with id '${section}' not found.`);
    }
  };

  return (
      <div className="app">
        <NavBar  onNavLinkClick={handleNavLinkClick} activeSection={activeSection} />
        <div className='components'>
          <Background id='Projects'
            backgroundImage={`url(${backgroundImage})`}
            logo={selectedProject.logo}
            description={selectedProject.description}
            demoUrl={selectedProject.url}
            projectTitle={selectedProject.title}
          />
          <Carousel scrollDistance={300}>
            {projectsData.map((project) => (
              <Box
                key={project.id}
                image={project.imageUrl}
                title={project.title}
                onClick={() => handleBoxClick(project)}
              />
            ))}
          </Carousel>
          <Perfil  />
          <Academico  />
          <Profissional />
          <Footer/>
        </div>
      </div>
  );
};

export default App;
