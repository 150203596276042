import React from "react";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import GitHubIcon from "@mui/icons-material/GitHub";
import LaptopIcon from "@mui/icons-material/Laptop";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SchoolIcon from "@mui/icons-material/School";
import PublicIcon from "@mui/icons-material/Public";
import FileOpenIcon from '@mui/icons-material/FileOpen';

import "./NavBar.css"



const NavBar = ({ onNavLinkClick, activeSection }) => {

  const links = {
    github: "https://github.com/yuricooke",
    linkedin: "https://www.linkedin.com/in/yuricooke",
    whatsapp: "https://wa.me/5521997423956/?text=Hi!%20I'm%20looking%20for%20an%20amazing%20front-end%20developer.",
    email: "mailto:yuricooke@yahoo.com",
    resumeLink: process.env.PUBLIC_URL + "/CV.pdf"
  };

  const handleLinkClick = (link) => {
    window.open(link, '_blank');
    console.log("You clicked: ", link )
  };


  return (
    <div className="nav-bar">
      <div className="nav-item">
        <LaptopIcon className={`nav-icon ${activeSection === "Projects" ? "active" : ""}`} onClick={() => onNavLinkClick("Projects")}/>
        <figcaption>Projects</figcaption>
      </div>
      <div className="nav-item">
        <AccountBoxIcon className={`nav-icon ${activeSection === "Perfil" ? "active" : ""}`} onClick={() => onNavLinkClick("Perfil")  }  />
        <figcaption><nobr>About Me</nobr></figcaption>
      </div>
      <div className="nav-item">
        <SchoolIcon className={`nav-icon ${activeSection === "Academico" ? "active" : ""}`} onClick={() => onNavLinkClick("Academico")}  />
        <figcaption>Academics</figcaption>
      </div>
      <div className="nav-item">
        <PublicIcon className={`nav-icon ${activeSection === "Profissional" ? "active" : ""}`} onClick={() => onNavLinkClick("Profissional")} />
        <figcaption>Work</figcaption>
      </div>

      <hr></hr>

      <div className="nav-item">
          <LinkedInIcon className="nav-icon" onClick={() => handleLinkClick(links.linkedin)} />
          <figcaption>LinkedIn</figcaption>
      </div>
      <div className="nav-item">
          <WhatsAppIcon className="nav-icon" onClick={() => handleLinkClick(links.whatsapp)}/>
          <figcaption>WhatsApp</figcaption>
      </div>
      <div className="nav-item">
          <GitHubIcon className="nav-icon" onClick={() => handleLinkClick(links.github)}/>
          <figcaption>Github</figcaption>
      </div>
      <div className="nav-item">
          <FileOpenIcon className="nav-icon" onClick={() => handleLinkClick(links.resumeLink)} />
          <figcaption>Curriculum</figcaption>
      </div>
    </div>
  );
};

export default NavBar;
