import React from 'react';
import './Footer.css';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import ContactMailIcon from '@mui/icons-material/ContactMail';
import FileOpenIcon from '@mui/icons-material/FileOpen';

const Footer = () => {
  
  const links = {
    github: "https://github.com/yuricooke",
    linkedin: "https://www.linkedin.com/in/yuricooke",
    whatsapp: "https://wa.me/5521997423956/?text=Hi!%20I'm%20looking%20for%20an%20amazing%20front-end%20developer.",
    email: "mailto:yuricooke@yahoo.com",
    resumeLink: process.env.PUBLIC_URL + "/CV.pdf"
  };

  const handleLinkClick = (link) => {
    window.open(link, '_blank');
    console.log("You clicked: ", link )
  };

  return (
    <div className='footer'>
        <h4>Yuri Cooke</h4>
        <hr></hr>
      <div className='features'>
      
      
      <div>
        <WhatsAppIcon className='icon-footer' onClick={() => handleLinkClick(links.whatsapp)}/>
        <GitHubIcon className='icon-footer' onClick={() => handleLinkClick(links.github)} />
        <LinkedInIcon className='icon-footer' onClick={() => handleLinkClick(links.linkedin)} />
        {/* <ContactMailIcon className='icon-footer' onClick={() => handleLinkClick(links.email)} /> */}
          <FileOpenIcon className='icon-footer' onClick={() => handleLinkClick(links.resumeLink)} />
      </div>
      <div>
        <p>Rio de Janeiro, BR</p>
      </div>
      <div>
          <p>2023 - All rights reserved</p>
      </div>
      </div>
    </div>
  );
}

export default Footer;
